import React, { useEffect } from "react";
import Home from "../Home";
import { useParams } from 'react-router-dom';

const isValidURL = (str) => {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
};

const Direct = () => {
  const { hash } = useParams();
  

  useEffect(() => {
    try {
      readData(hash);
    } catch (error) {}
  }, []);

  const readData = (hash) => {
    const url = "http://short.language123.net/api/readFile.php";
    const formData = new FormData();
    formData.append('hash', hash);
    fetch(url, {
      method: 'POST',
      body: formData
    }).then(res => res.text()).then(json => openLink(json));
  };

  const openLink = link => {
    const prefix1 = 'http://';
    const prefix2 = 'https://';
    console.log(link);
    if (link.startsWith(prefix1) || link.startsWith(prefix2)) {
      window.open(link, "_self", "");
    };
  };

  return (
    <Home />
  );
}

export default Direct;
