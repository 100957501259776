import React, { useState } from "react";
import "./Home.css";
import shortHash from "short-hash";

const Home = () => {
  const [fullLink, setFullLink] = useState("");
  const [shortLink, setShortLink] = useState("");

  const onChangeFullLink = (event) => {
    setFullLink(event.target.value);
  };

  const writeDataAgain = () => {
    const url = "http://short.language123.net/api/writeFile.php";
    const formData = new FormData();
    formData.append('hash', shortHash(fullLink));
    formData.append('value', fullLink);
    fetch(url, {
      method: 'POST',
      body: formData
    }).then(res => res.text()).then(json => console.log(json));
  };

  const writeData = () => {
    const url = "http://short.language123.net/api/writeFile.php";
    const formData = new FormData();
    formData.append('hash', shortHash(fullLink));
    formData.append('value', fullLink);
    fetch(url, {
      method: 'POST',
      body: formData
    }).then(res => res.text()).then(json => writeDataAgain());
  };

  const handleSubmit = (event) => {
    setShortLink(shortHash(fullLink));
    event.preventDefault();
    writeData();
  };

  return (
    <form onSubmit={handleSubmit} className="container">
      <input
        className="fullLinkInput"
        type="text"
        name="fulllink"
        value={fullLink}
        onChange={onChangeFullLink}
        placeholder='https://...'
      />
      <br />
      <input className="submitButton" type="submit" value="Get Short Link" />
      <br />
      <label className="shortLinkLabel">{shortLink ? `http://short.language123.net/${shortLink}` : ''}</label>
    </form>
  );
}

export default Home;
